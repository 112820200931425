import React, { useEffect, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import access from "../../images/access.png";
import info from "../../images/info.png";
import celebrate from "../../images/celebrate.png";
import submit from "../../images/submit.png";
import shopeeAccess from "../../images/shopee/access.png";
import shopeeInfo from "../../images/shopee/info.png";
import shopeeCelebrate from "../../images/shopee/celebrate.png";
import shopeeSubmit from "../../images/shopee/submit.png";
import elecboyAccess from "../../images/elecboy/access.png";
import elecboyInfo from "../../images/elecboy/info.png";
import elecboyCelebrate from "../../images/elecboy/celebrate.png";
import elecboySubmit from "../../images/elecboy/submit.png";
import hkAccess from "../../images/homecredit/PH/access.png";
import hkInfo from "../../images/homecredit/PH/info.png";
import hkCelebrate from "../../images/homecredit/PH/celebrate.png";
import hkSubmit from "../../images/homecredit/PH/submit.png";
import "./workflow.scss";
import { PARTNER_DETAILS } from "../../utils/constants";

function WorkFlow() {
  const [state, setState] = useState({ processFlow: [] });
  const intl = useIntl();

  useEffect(async () => {
    const processFlow = [
      {
        img: iconFunct('access'),
        title: intl.formatMessage({ id:"wfTitleS1"}),
        description: intl.formatMessage({ id:"wfDescS1"}),
      },
      {
        img: iconFunct('info'),
        title: intl.formatMessage({ id:"wfTitleS2"}),
        description: intl.formatMessage({ id:"wfDescS2"}),
      },
      {
        img: iconFunct('submit'),
        title: intl.formatMessage({ id:"wfTitleS3"}),
        description: intl.formatMessage({ id:"wfDescS3"}),
      },
      {
        img: iconFunct('celebrate'),
        title: intl.formatMessage({ id:"wfTitleS4"}),
        description: intl.formatMessage({ id:"wfDescS4"}),
      },
    ];

    await setState({ ...state, processFlow });
    handleArrowDirection();

    window.addEventListener("resize", handleArrowDirection);
  }, []);

  const iconFunct = (iconFlag) => {
    if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.SHOPEE) {
      if (iconFlag === 'access') { 
        return shopeeAccess;
      } else if (iconFlag === 'info') { 
        return shopeeInfo;
      } else if (iconFlag === 'submit') {
        return shopeeSubmit;
      } else {
        return shopeeCelebrate;
      }
    } else if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.ELECBOY) {
      if (iconFlag === 'access') {
        return elecboyAccess;
      } else if (iconFlag === 'info') {
        return elecboyInfo;
      } else if (iconFlag === 'submit') {
        return elecboySubmit;
      } else {
        return elecboyCelebrate;
      }
    } if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT) {
      if (iconFlag === 'access') { 
        return hkAccess;
      } else if (iconFlag === 'info') { 
        return hkInfo;
      } else if (iconFlag === 'submit') {
        return hkSubmit;
      } else {
        return hkCelebrate;
      }
    } else {
      if (iconFlag === 'access') {
        return access;
      } else if (iconFlag === 'info') {
        return info;
      } else if (iconFlag === 'submit') {
        return submit;
      } else {
        return celebrate;
      }
    }
  };

  const handleArrowDirection = () => {
    const currentWidth = window.innerWidth;
    const p1 = document.getElementById("p-0");
    const p2 = document.getElementById("p-1");
    const p3 = document.getElementById("p-2");
    const p4 = document.getElementById("p-3");

    const clearClasses = () => {
      p1.classList.remove("rightArrow", "downArrow");
      p2.classList.remove("rightArrow", "downArrow");
      p3.classList.remove("rightArrow", "downArrow");
      p4.classList.remove("rightArrow", "downArrow");
    };

    if (currentWidth < 768) {
      clearClasses();

      p1.classList.add("downArrow");
      p2.classList.add("downArrow");
      p3.classList.add("downArrow");
    } else {
      clearClasses();

      p1.classList.add("rightArrow");
      p2.classList.add("rightArrow");
      p3.classList.add("rightArrow");
    }
  };

  return (
    <section className="workflow-container">
      <div className="workflow-wrap main-container">
        <div className="head-text text-navy">
          {intl.formatMessage({ id:"howItWorks"})}
        </div>
        <div className="workflow-steps">
          {state.processFlow.map((process, index) => (
            <div
              key={`p-${index}`}
              id={`p-${index}`}
              className={`step-wrap rightArrow`}
            >
              <img className="step-img" src={process.img} alt="icon" />
              <p className="step-title text-navy">{process.title}</p>
              <p className="step-desc text-navy">{process.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default WorkFlow;
