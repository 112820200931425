import React, { useEffect, useState } from "react";
import up from "../../images/up.png";
import down from "../../images/down.png";
import { useIntl } from "gatsby-plugin-intl";
import "./support.scss";
import Dropdown from "react-dropdown";
import { PARTNER_COUNTRY, PARTNER_DETAILS } from "../../utils/constants";

function Support() {
  const intl = useIntl();
  const [state, setState] = useState({ questions: [] });
  let questions = [];
  let isMyPartner = false;
  let queSubString = 'Sales';
  let ansSubString = 'SalesAns';
  let defaultPartner = '';
  let totalFAQs = process.env.GATSBY_TOTAL_FAQs;

  if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.BOLTTECH && ((process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MY) || (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MYDIGI ))) {
    if (process.env.GATSBY_COUNTRY === PARTNER_COUNTRY.MY) {
      isMyPartner = true;
      defaultPartner = 'aeon';
    } else {
      defaultPartner = 'digi';
    }
  } else {
    queSubString = 'faq';
    ansSubString = 'faqAns';
  }
  const [currentPartner, setCurrentPartner] = useState(defaultPartner ? defaultPartner : '');
  
  for(let i=1; i<=totalFAQs; i++) {
    questions.push({
      title: intl.formatMessage({id: `${currentPartner}${queSubString}-${i}`}),
      answer: intl.formatHTMLMessage({id: `${currentPartner}${ansSubString}-${i}`}),
      expanded: false
    })
  }

  const options = [
    { value: 'aeon', label: 'AEON SAFEGUARD' },
    { value: 'ascendSp', label: 'Ascend SP' },
    { value: 'ultimateDevices', label: 'Ultimate devices' },
    { value: 'tmUnifi', label: 'TM unifi' },
    { value: 'allIT', label: 'All IT' }
  ];

  const onPartnerChange = (e) => {
    setCurrentPartner(e.value);
  };
  
  useEffect(async () => {
    await setState({ ...state, questions });
  }, [currentPartner]);

  const toggleQuestion = (question, index) => {
    const newQuestions = [...state.questions];

    newQuestions[index].expanded = !question.expanded;

    setState({ ...state, questions: newQuestions });
  };

  return (
    <section>
      <div className="faq-wrap" id="faqs">
        <div className="faq-inner main-container">
          <div className="faq-flex">
            <div className="faq-header text-navy">
            {intl.formatMessage({id: "faqHeading"})}
            </div>
            {isMyPartner && 
              <div className="dropdownContainer">
                  <span>{intl.formatMessage({id: "dropdownHeading"})}</span>
                  <Dropdown
                    options={options}
                    onChange={onPartnerChange}
                    value={currentPartner}
                    placeholder="Partner"
                  />
                </div> 
            } 
            <div className="faq-q-a-flex">
              {state.questions.map((question, index) => (
                <div
                  key={`q-${index}`}
                  id={`q-${index}`}
                  className="faq-q-wrap"
                >
                  <div className="text-navy faq-q" onClick={() => toggleQuestion(question, index)}>
                    <span className="faq-title">{question.title}</span>
                    <span className="faq-ar">
                      {question.expanded ? (
                        <img
                          src={up}
                          alt="up"
                        />
                      ) : (
                        <img
                          src={down}
                          alt="down"
                        />
                      )}
                    </span>
                  </div>

                  {question.expanded && (
                    <div className="text-navy faq-a">
                      <div
                        dangerouslySetInnerHTML={{ __html: question.answer }}
                      ></div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Support;
