import React, { useState } from "react";
import fbLogo from "../../images/fb.png";
import mailLogo from "../../images/email.png";
import phoneLogo from "../../images/call.png";
import viberLogo from "../../images/viber.svg";
import hkFbLogo from "../../images/homecredit/PH/facebook.png";
import hkMailLogo from "../../images/homecredit/PH/email.png";
import hkElecboyMailLogo from "../../images/elecboy/email.png";
import hkViberLogo from "../../images/homecredit/PH/viber.png";
import hkPhoneLogo from "../../images/homecredit/PH/call.png";
import viber_PH_uat from "../../images/bdp/PH/viber_chat_uat.png";
import viber_PH_prod from "../../images/bdp/PH/viber_chat_prod.png";
import eirMailLogo from "../../images/eir/email.png";
import eirPhoneLogo from "../../images/eir/phone.png"
import br from "../../images/blue-right.png";
import { useIntl, FormattedHTMLMessage } from "gatsby-plugin-intl";
import "./otherReqOptions.scss";
import { GTM_TAGS, OTHER_REQ_OPTIONS, PARTNER_DETAILS } from "./../../utils/constants";
import { gtmEventTrack } from "../../utils/utilities";

function OtherRequestOptions() {
  const intl = useIntl();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = (GTMTrackType, device) => {
    if (!device) {
      setModalOpen(!modalOpen);
    }
    gtmEventTrack({
      ...GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY][
        GTMTrackType
      ].CLICK,
      action: "Click",
    });
  };

  const handleGTM = (GTMTrackType) => {
    if (GTMTrackType !== null) {
      gtmEventTrack({
        ...GTM_TAGS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY][
          GTMTrackType
        ].CLICK,
        action: "Click",
      });
    }
  };

  const getSectionHeading = () => {
    return (
      <FormattedHTMLMessage
        id={
          OTHER_REQ_OPTIONS[process.env.GATSBY_PARTNER][
            process.env.GATSBY_COUNTRY
          ].SECTION_HEADING
        }
      />
    );
  };

  const getWorkingHours = () => {
    return intl.formatHTMLMessage({
      id: OTHER_REQ_OPTIONS[process.env.GATSBY_PARTNER][
        process.env.GATSBY_COUNTRY
      ].WORKING_DAYS,
    });
  };

  const renderConnectOptions = () => {
    let optionsHTML = [];
    const allOptions =
      OTHER_REQ_OPTIONS[process.env.GATSBY_PARTNER][process.env.GATSBY_COUNTRY]
        .CONNECT_OPTIONS;

    const getViberQR = () => {
      if (
        process.env.GATSBY_PARTNER === "bdp" &&
        process.env.GATSBY_COUNTRY === "PH" &&
        process.env.GATSBY_ENV === "uat"
      ) {
        return viber_PH_uat;
      } else {
        return viber_PH_prod;
      }
    };

    const renderLinkAction = (connectOption) => {
      const type = connectOption.linkType;

      switch (type) {
        case "hyperlink": {
          return (
            <p className="link-txt">
              <a
                href={intl.formatMessage({
                  id: connectOption.linkText.webLink,
                })}
                rel="noreferrer"
                onClick={() => handleGTM(connectOption.gtmTrackKey)}
                target="_blank"
              >
                {intl.formatMessage({ id: connectOption.linkText.label })}
              </a>
            </p>
          );
        }

        case "email": {
          return (
            <p className="link-txt">
              <a
                href={`mailto:${intl.formatMessage({
                  id: connectOption.linkText.webLink,
                })}`}
                rel="noreferrer"
                target="_blank"
              >
                {intl.formatMessage({ id: connectOption.linkText.label })}
              </a>
            </p>
          );
        }

        case "QRCode": {
          return (
            <>
              <div className="display-none display-desktop">
                <p className="link-txt">
                  <a rel="noreferrer" className="chat-via-viber" onClick={() => toggleModal('VIBER')} href="#!">
                    {intl.formatMessage({ id: connectOption.linkText })}
                  </a>
                </p>
                {modalOpen && (
                  <div className="custom-modal" id="chatViaViberModel">
                    <div className="text-center">
                      <div className="close-modal" onClick={() => setModalOpen(!modalOpen)}>
                        <img
                          src={require("../../images/cross_icon.svg")}
                          alt="Close"
                        />
                      </div>
                      <p className="font-medium">
                        <FormattedHTMLMessage id="viberScanHeading" />
                      </p>
                      <img src={getViberQR()} alt="ViberQR" />
                      <p>
                        <FormattedHTMLMessage id="viberScanBody" />
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="display-none display-mobile display-tablet">
                <p className="link-txt">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    className="chat-via-viber"
                    onClick={() => toggleModal('VIBER', 'mobile')}
                    href={process.env.GATSBY_VIBER_URL}
                  >
                    {intl.formatMessage({ id: connectOption.linkText })}
                  </a>
                </p>
              </div>
            </>
          );
        }

        case "telephone": {
          return (
            <p className="link-txt">
              <a
                href={`tel:${intl.formatMessage({
                  id: connectOption.linkText,
                })}`}
                rel="noreferrer"
                target="_blank"
              >
                {process.env.GATSBY_PARTNER === 'eir' ?
                  intl.formatMessage({ id: "phoneNumTxt" }) + ' ' + intl.formatMessage({id: connectOption.linkText }):
                intl.formatMessage({ id: connectOption.linkText })
                }
              </a>
            </p>
          );
        }

        default:
          return "";
      }
    };

    const getLogo = (logoName) => {
      switch (logoName) {
        case "email": {
          if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT) {
            return <img src={hkMailLogo} alt="email" />;
          } else if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.ELECBOY) {
            return <img src={hkElecboyMailLogo} alt="email" />;
          } else {
            return <img src={mailLogo} alt="email" />;
          }
        }

        case "fb":{
          if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT) {
            return <img src={hkFbLogo} alt="FB" />;
          } else {
            return <img src={fbLogo} alt="FB" />;
          }
        }
        case "phone":{
          if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT) {
            return <img src={hkPhoneLogo} alt="Contact" />;
          } else {
            return <img src={phoneLogo} alt="Contact" />;
          }
        }
        case "viber":{
          if (process.env.GATSBY_PARTNER === PARTNER_DETAILS.HOMECREDIT) {
            return <img src={hkViberLogo} alt="Viber" />;
          } else {
            return <img src={viberLogo} alt="Viber" />;
          }
        }
        case "eir/phone":
          return <img src={eirPhoneLogo} alt="Contact" />;
          
        case "eir/email":
          return <img src={eirMailLogo} alt="email" />  
      }
    };

    for (let i = 0; i < allOptions.length; i++) {
      if (process.env.GATSBY_PARTNER !== 'eir') {
        optionsHTML.push(
          <div className="other-req-opt" key={`req-${i}`}>
            {getLogo(allOptions[i].iconName)}
            <div className="other-req-opt-values">
              {allOptions[i].values.map((cntOptValue, index) => (
                <div key={`opt-${index}`} className="req-txt-wrap">
                  <div className="req-txt">
                    <p>
                      {intl.formatMessage({
                        id: cntOptValue.titleTextId,
                      })}
                    </p>
                    
                    {renderLinkAction(cntOptValue)}

                    {cntOptValue.newPhone1 && (
                      <p className="link-txt">
                        <a
                          href={`tel:${intl.formatMessage({
                            id: cntOptValue.newPhone1,
                          })}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {intl.formatMessage({ id: cntOptValue.newPhone1 })}
                        </a>
                    </p>
                    )}
                    {cntOptValue.newPhone2 && (
                      <p className="link-txt">
                      <a
                        href={`tel:${intl.formatMessage({
                          id: cntOptValue.newPhone2,
                        })}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {intl.formatMessage({ id: cntOptValue.newPhone2 })}
                      </a>
                    </p>
                    )}
                  </div>
                  <img className="right-ar" src={br} alt="ra" />
                </div>
              ))}
            </div>
          </div>
        );
      } else if (process.env.GATSBY_PARTNER === 'eir') {
        optionsHTML.push(
          <div className="other-req-opt" key={`req-${i}`}>
            <div className="icon-header-txt">
            {getLogo(allOptions[i].iconName)}
            {intl.formatMessage({ id: allOptions[i].values[0].titleTextId,
                      })}
                      </div>
            <div className="other-req-opt-values">
              {allOptions[i].values.map((cntOptValue, index) => (
                <div key={`opt-${index}`} className="req-txt-wrap">
                  <br/>
                  <div className="req-txt">
                    {renderLinkAction(cntOptValue)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      }
    }
    return optionsHTML;
  };

  return (
    <section>
      <div className="other-req-wrap bg-cyan">
        <div className="other-req-inner">
          <div className="other-req-flex text-navy">
            <div className="other-req-header">
              <p className="other-req-heading">{getSectionHeading()}</p>
              <p className="other-req-text">{getWorkingHours()}</p>
            </div>
            <div className="other-req-opt-wrap">
              <div className="other-req-opt-inner">
                {renderConnectOptions()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OtherRequestOptions;
