import React from "react";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import './note.scss';

const NoteComponent = () => {
    return (
        <div className="container">
            <div className="info"><FormattedHTMLMessage id="portalNote" /></div>
        </div>
    )
}

export default NoteComponent;