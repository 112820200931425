import React from 'react'
import './samsungbanner.modules.scss'
import Dropdown from "react-dropdown";
import { changeLocale, IntlContextConsumer, useIntl } from "gatsby-plugin-intl";

export default function BannerComp() {
    const intl = useIntl();
    const language = intl.locale;
    const bannerMobile = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY.toUpperCase()}/${language}/banner-mobile.png`);
    const bannerTablet = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY.toUpperCase()}/${language}/banner-tablet.png`);
    const bannerDesktop = require(`../../images/${process.env.GATSBY_PARTNER}/${process.env.GATSBY_COUNTRY.toUpperCase()}/${language}/banner.png`);

    const onLangChange = (lang) => {
        changeLocale(lang.value);
    };

    return (
        <>
            <div className='bannerWrapper'>
                <div className='d-flex justify-content-between p-30'>
                    <div className='samsung-logo' />
                    <div className="lang-wrap">
                    <IntlContextConsumer>
                        {({ languages, language: currentLocale }) =>
                        languages.length > 1 && (
                            <Dropdown
                                options={languages}
                                onChange={onLangChange}
                                value={currentLocale}
                                placeholder="Lang"
                                />
                        )}
                    </IntlContextConsumer>
                    </div>
                </div>
                <div className="home-banner">
                    <img
                        src={bannerMobile}
                        className="display-none display-mobile"
                        alt="Bolttech"
                    />
                    <img
                        src={bannerTablet}
                        className="display-none display-tablet tablet-banner-style"
                        alt="Bolttech"
                    />
                    <img
                        src={bannerDesktop}
                        className="display-none display-desktop"
                        alt="Bolttech"
                    />
                </div>
            </div>
        </>
    )
}
